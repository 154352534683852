<template>
  <div>
    <div class="s-layout__index_header">
      <div class="btn-group" role="group">
        <button
          type="button"
          @click="$router.push({ name: 'troubleshooting-index' })"
          class="btn"
          :class="$route.name == 'troubleshooting-index' ? 'active' : ''"
        >
          <i class="bi bi-clipboard-check-fill"></i>

          Scan
        </button>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
